<template>
  <div class="contact-page">
    <div class="contact-container">
      <div class="image-container">
        <img
          src="../assets/we-all-die-twice-kindle-tablet-cover.png"
          alt="We All Die Twice Cover"
          class="fade-in"
        />
      </div>
      <form @submit.prevent="submitForm" class="contact-form">
        <h2>Get 3 Free Chapters</h2>
        <div class="form-group">
          <label for="email">Email Address (required)</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="form-group">
          <label for="message">Your Message to Us (optional)</label>
          <textarea id="message" v-model="message" rows="5"></textarea>
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
    <p>&nbsp;</p>
    <div v-if="notification.show" :class="['notification', notification.type]">
      {{ notification.message }}
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact3Chapters",
  data() {
    return {
      email: "",
      message: "",
      notification: {
        show: false,
        message: "",
        type: "success",
      },
    };
  },
  methods: {
    async submitForm() {
      //const baseURL = "http://msg.a13z.org:3000/api/send-email"
      const baseURL = "https://msg.a13z.org:3000";
      try {
        await axios.post(`${baseURL}/api/send-email`, {
          email: this.email,
          message: this.message,
        });
        this.showNotification(
          "Free chapters sent successfully! Check your email.",
          "success"
        );
        this.resetForm();
      } catch (error) {
        console.error("Error sending email:", error);
        this.showNotification(
          "Failed to send free chapters. Please try again." + error,
          "error"
        );
      }
    },
    showNotification(message, type) {
      this.notification = { show: true, message, type };
      setTimeout(() => {
        this.notification.show = false;
      }, 3000);
    },
    resetForm() {
      this.email = "";
      this.message = "";
    },
  },
};
</script>

<style src="../a13z.css" scoped></style>
